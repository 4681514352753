<template>

<html lang=en>
    <div class="center">
    <head>
        <meta name="viewport" content="width=device-width, initial-scale=1">
    </head>
  <div id="profile">
    
    <img class="image_style" alt="portfolio picture" src="../assets/ta.png">

    <div class="name">Caleb Ogbonnaya</div>
    <div class="profession">Full Stack Developer</div>
    <div class="para"><b>I'm into <a class="link" href="https://cloud.google.com/cloud-build" target="_blank" rel="noopener noreferrer">Continuous Integration</a>, <a class="link" href="https://kubernetes.io/" target="_blank" rel="noopener noreferrer">Cloud-Native Technologies</a>, and <a class="link" href="https://www.oracle.com/internet-of-things/what-is-iot/" target="_blank" rel="noopener noreferrer">IoT</a></b>.</div>
    <div class="para"><b>Sometimes I <a class="link" href="https://www.my-best-kite.com/" target="_blank" rel="noopener noreferrer">Fly Kites</a> or watch <a class="link" href="https://www.imdb.com/title/tt6400614/?ref_=vp_vi_tt" target="_blank" rel="noopener noreferrer">Documentaries</a> and other times I just pet my cat</b>.</div>
    <div class="para"><b>I currently work at <a class="link" href="https://www.beamsuntory.com/en" target="_blank" rel="noopener noreferrer">Beam Suntory</a></b>.</div>
    <div class="para"><b>Feel free to check out <a class="link" href="https://drive.google.com/file/d/1PNFdGK538bBA6aaqzmqX4GkFxKkm2QWi/view?usp=sharing" target="_blank" rel="noopener noreferrer">my resume</a> or see my recent activity below</b>.</div>
    <div class="icons">
    <span class="icon"><a class="link-icon" href="https://linkedin.com/in/caleb-ogbonnaya-8670a662" target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin"></i></a></span>
    <span class="icon"><a class="link-icon" href="https://github.com/chs74515" target="_blank" rel="noopener noreferrer"><i class="fab fa-github"></i></a></span>
    <span class="icon"><a class="link-icon" href="https://dl.acm.org/doi/10.1145/3077286.3077313" target="_blank" rel="noopener noreferrer"><i class="fas fa-laptop-house"></i></a></span>
    </div> 
  </div>
  </div>
</html>

</template>

<script>
export default {
  name: 'Profile',
}
</script>

<style>
.name {
    color: white;
    font-size: 3.6rem;
    font-weight: bolder;
    padding: 20px;
}
.profession {
    color: white;
    font-size: 1.6rem;
    padding-bottom: 24px;
    font-family: Rubik;
}
.para {
    /* color: white; */
    /* color: #343434 !important; */
    color: white !important;
    font-size: 1.2rem;
    padding: 10px;
    font-family: Heebo;
    animation: fadeInAnimation ease 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

 @keyframes fadeInAnimation {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
}


.link{
    /* color: 7d3cff; */
    color: #feb300;
    /* color: rgb(106, 119, 242); */
    text-decoration: none;
    font-weight: 800;
    cursor: pointer;

}
.link-icon{
    color: white;
    opacity: 1;
    transform: none;
    text-align: center;

}

span.icon {
    font-size: 30px;
    padding: 34px;
    color: wheat;
    animation: fadeInAnimation2 ease 15s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}
@keyframes fadeInAnimation2 {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

.icons {
    margin-top: 50px;
    color: whitesmoke;
    cursor: pointer;

}

html, body {
    font-size: calc(10px + 0.33vw);
    width: 100%;
    height:100%;
    overflow: hidden;
    margin: 0px;
    padding: 0px;
}

body {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.image_style{
    width: 280px;
    height: 280px;
    margin: 0px 0px 2rem;
    opacity: 1;
    image-rendering: auto;
    animation: fadeIn ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
}

@media screen and (max-width: 992px)
{
    .center {
        max-width: 90vw;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}

@media screen and (min-width: 496px)
{
    .center {
        min-width: 40vw;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
}

</style>
